import {sample} from "effector";
import {setUserAttributesEv, setUserGroupsEv, setUserKeysEv, setUserUIDDataEv} from "./events";
import {$placeIdOptions, $userAttributes, $userGroups, $userKeys, $userUIDData} from "./stores";
import {getPlaceIdsFx} from "./effects";

$userAttributes.on(setUserAttributesEv, (state, payload) => payload)

$userUIDData.on(setUserUIDDataEv, (_, payload) => payload)

$placeIdOptions.on(getPlaceIdsFx.doneData, (_, payload) => {
	return payload.map(item => {
		return {
			label: item.place_address,
			value: item.place_id
		}
	})
})

sample({
	clock: setUserGroupsEv,
	target: $userGroups
})

sample({
	clock: setUserKeysEv,
	target: $userKeys
})
