import React, {useEffect} from 'react';
import {Button, Form, Modal, Upload} from "antd";
import style from "./SchoolUserList.module.css";
import EmpForm from "./Subforms/EmpForm";
import StudentForm from "./Subforms/StudentForm";
import {$editUserEdu, resetEditUserEduEv} from "../../models/userListModel";
import {useUnit} from "effector-react";
import {UploadOutlined} from "@ant-design/icons";

function EditUserModal({type}) {
	const editUserEdu = useUnit($editUserEdu)
	const [form] = Form.useForm();
	const resetEditUserEdu = useUnit(resetEditUserEduEv)
	const loading = false

	const onClose = () => {
		resetEditUserEdu()
		form.resetFields()
	}

	const onFinish = (values) => {
		console.log(values)
	}

	const normFile = (info) => {
		return info?.file;
	};

	useEffect(() => {
		if(editUserEdu){
			form.setFieldsValue(editUserEdu)
		}
	}, [editUserEdu]);

	return (
		<Modal
			open={!!editUserEdu}
			onCancel={onClose}
			title={`Изменить ${type === 'employee' ? 'сотрудника' : 'школьника'}`}
			onOk={form.submit}
			footer={null}
			width={"699px"}
			destroyOnClose
			afterClose={() => form.resetFields()}
		>
			<Form
				form={form}
				onFinish={onFinish}
				layout={"vertical"}
				style={{
					marginTop: "16px",
				}}
			>
				<Form.Item name="data_url" getValueFromEvent={normFile}>
					<Upload beforeUpload={() => false} listType={"picture"}>
						<Button icon={<UploadOutlined />}>Загрузить</Button>
					</Upload>
				</Form.Item>
				{type === 'employee' ? (
					<EmpForm form={form} />
				) : (
					<StudentForm />
				)}
			</Form>
			<Button
				onClick={form.submit}
				className={style.modal_button}
				type={"primary"}
				style={{ marginRight: "8px" }}
				loading={loading}
			>
				Добавить
			</Button>
			<Button onClick={onClose} className={style.modal_button}>
				Отмена
			</Button>
		</Modal>
	);
}

export default EditUserModal;
