import {$dashboardList, $editDashboard, $showCreateDashboardModal, $viewDashboard} from "./stores";
import {getDashboardListFx} from "./effects";
import {
	resetCrateDashboardModalEv,
	resetEditDashboardEv,
	resetViewDashboardEv,
	setEditDashboardEv,
	toggleCreateDashboardModalEv
} from "./events";
import {
	sendActivateTenantItemFx,
	sendCreateTenantItemFx,
	sendDisableTenantItemFx,
	sendEditTenantItemFx,
	sendViewTenantItemFx
} from "../portalUsersModel";
import {sample} from "effector";

$dashboardList.on(getDashboardListFx.doneData, (_, payload) => payload)

$showCreateDashboardModal.on(toggleCreateDashboardModalEv, (state, payload) => {
	if(typeof payload === 'boolean') return payload
	return !state
}).reset(resetCrateDashboardModalEv)

$editDashboard.on(setEditDashboardEv, (_, payload) => payload)
	.reset(resetEditDashboardEv)

$viewDashboard.on(sendViewTenantItemFx.doneData, (_, payload) => payload)
	.reset(resetViewDashboardEv)

sample({
	source: $dashboardList,
	clock: sendCreateTenantItemFx.doneData,
	filter: (_, clock) => clock.sort_key.includes('DID#'),
	fn: (source, clock) => {
		return [...source, clock]
	},
	target: [$dashboardList, resetCrateDashboardModalEv]
})

sample({
	source: [$dashboardList, $editDashboard],
	clock: [sendEditTenantItemFx.doneData, sendDisableTenantItemFx.doneData, sendActivateTenantItemFx.doneData],
	filter: (_, clock) => clock.sort_key.includes('DID#'),
	fn: ([list, oldItem], clock) => {
		let {partition_key} = clock
		if(oldItem){
			partition_key = oldItem.partition_key
		}
		const updateIndex = list.findIndex(item => item.partition_key === partition_key)
		return [
			...list.slice(0, updateIndex),
			clock,
			...list.slice(updateIndex + 1)
		]
	},
	target: [$dashboardList, resetEditDashboardEv]
})
