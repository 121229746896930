import React, { useEffect } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import style from "../SchoolUserList.module.css";
import {
  defaultEndWorkTime,
  defaultStartWorkTime,
} from "../../../dictionaries/edu-dictionary";
import dayjs from "dayjs";

const workTypeOptions = [
  {
    label: "Стандартный",
    value: "Стандартный",
  },
  {
    label: "Нестандартный",
    value: "Нестандартный",
  },
];
const format = "HH:mm";

function EmpForm({ form }) {
  const work_type = Form.useWatch("work_type", form);

  return (
    <>
      <Row justify={"space-between"} gutter={8}>
        <Col span={12}>
          <Form.Item
            name={"surname"}
            label={"Фамилия"}
          >
            <Input placeholder={"Введите фамилию"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"name"}
            label={"Имя"}
          >
            <Input placeholder={"Введите имя"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"name"}
            label={"Имя"}
          >
            <Input placeholder={"Введите имя"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"management"}
            label={"Управление"}
          >
            <Select
              placeholder={"Выбрать"}
              options={[]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"position"}
            label={"Должность"}
          >
            <Input placeholder={"Введите должность"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"subjects"}
            label={"Предметы"}
          >
            <Select
              placeholder={"Выбрать"}
              options={[]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"birthday"}
            label={"Дата рождения"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"address"}
            label={"Адрес"}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={"gender"} label={"Пол"}>
        <Radio.Group>
          <Radio value={"Женщина"}>Женщина</Radio>
          <Radio value={"Мужчина"}>Мужчина</Radio>
        </Radio.Group>
      </Form.Item>
      <Row justify={"space-between"} gutter={8}>
        <Col span={12}>
          <Form.Item
            name={"emp_date"}
            label={"Дата приема на работу"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"fire_date"}
            label={"Дата увольнения"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"start_work_time"}
            label={"Время начала раб. дня"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"end_work_time"}
            label={"Время окончания раб. дня"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"email"}
            label={"Почта"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"phone"}
            label={"Телефон"}
          >
            <Input placeholder={'+'}/>
          </Form.Item>
        </Col>
      </Row>
      {/*{work_type && work_type !== "Стандартный" ? (*/}
      {/*  <>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Понедельник</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Понедельник"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Понедельник"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Вторник</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Вторник"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Вторник"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Среда</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Среда"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Среда"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Четверг</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Четверг"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Четверг"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Пятница</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Пятница"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Пятница"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Суббота</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Суббота"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Суббота"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={style.modal_day_wrapper}>*/}
      {/*      <div className={style.modal_day_title}>Воскресенье</div>*/}
      {/*      <div className={style.modal_day_timers}>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "start_work_time", "Воскресенье"]}*/}
      {/*          label={"Время начала рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("09:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          style={{ marginBottom: 0 }}*/}
      {/*          name={["properties", "end_work_time", "Воскресенье"]}*/}
      {/*          label={"Время окончания рабочего дня"}*/}
      {/*        >*/}
      {/*          <TimePicker*/}
      {/*            style={{ width: 300 }}*/}
      {/*            defaultValue={dayjs("17:00", "HH:mm")}*/}
      {/*            showNow={false}*/}
      {/*            locale={locale}*/}
      {/*            format={format}*/}
      {/*          />*/}
      {/*        </Form.Item>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
    </>
  );
}

export default EmpForm;
