import React from "react";
import { Button, Card, Col, Form, Input, Radio, Row, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import style from "../SchoolUserList.module.css";

const workTypeOptions = [
  {
    label: "Стандартный",
    value: "Стандартный",
  },
  {
    label: "Нестандартный",
    value: "Нестандартный",
  },
];

function StudentForm() {
  return (
    <>
      <Row justify={"space-between"} gutter={8}>
        <Col span={12}>
          <Form.Item
            name={"surname"}
            label={"Фамилия"}
          >
            <Input placeholder={"Введите фамилию"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"name"}
            label={"Имя"}
          >
            <Input placeholder={"Введите имя"} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"class"}
            label={"Класс"}
          >
            <Select
              placeholder={"Выбрать"}
              options={[]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"shift"}
            label={"Смена"}
          >
            <Select
              placeholder={"Выбрать"}
              options={[]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"birthday"}
            label={"Дата рождения"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"subjects"}
            label={"Предметы"}
          >
            <Select
              placeholder={"Выбрать"}
              options={[]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={"gender"} label={"Пол"}>
        <Radio.Group>
          <Radio value={"Female"}>Женщина</Radio>
          <Radio value={"Male"}>Мужчина</Radio>
        </Radio.Group>
      </Form.Item>
      <Row justify={"space-between"} gutter={8}>
        <Col span={12}>
          <Form.Item
            name={"start_work_time"}
            label={"Время начала раб. дня"}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={"end_work_time"}
            label={"Время окончания раб. дня"}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name={"contacts"}>
        {(fields, { add, remove }) => (
          <div className={style.contacts_wrapper}>
            <div className={style.contacts_top}>
              <div className={style.contacts_title}>Контакты</div>
              <Button onClick={() => add()}>+ Добавить контакт</Button>
            </div>
            <div className={style.contacts}>
              {fields.map((field, index) => (
                <div className={style.modal_contact}>
                  <Row justify={"space-between"} gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "type"]}
                        label={"Тип контакта"}
                      >
                        <Select
                          placeholder={"Выбрать"}
                          options={[]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "phone"]}
                        label={"Телефон"}
                      >
                        <Input placeholder={"+"}/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "surname"]}
                        label={"Фаимилия"}
                      >
                        <Input placeholder={"Введите фамилию"}/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "name"]}
                        label={"Имя"}
                        style={{marginBottom: 0}}
                      >
                        <Input placeholder={"Введите имя"}/>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "email"]}
                        label={"Почта контакта"}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "address"]}
                        label={"Адрес"}
                      >
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}>
                    <Button className={style.contact_delete} onClick={() => remove(field.name)}>Удалить</Button>
                  </div>
                  {
                    index !== fields.length - 1 && <div className={style.contacts_line}></div>
                  }
                </div>
              ))}
            </div>
          </div>
        )}
      </Form.List>
    </>
  );
}

export default StudentForm;
