import React, {useEffect} from 'react';
import {Form, Modal} from "antd";
import PortalOrgForm from "./PortalOrgForm";
import {useUnit} from "effector-react";
import {
	$showCreateOrgModal,
	sendCreateOrgEv, sendCreateTenantItemEv,
	sendCreateTenantItemFx,
	toggleCreateOrgModalEv
} from "../../../../models/portalUsersModel";

function CreateOrgModal() {
	const show = useUnit($showCreateOrgModal)
	const toggleCreateOrgModal = useUnit(toggleCreateOrgModalEv)
	const [createForm] = Form.useForm()
	const sendCreateOrg = useUnit(sendCreateTenantItemEv)
	const loading = useUnit(sendCreateTenantItemFx.pending)

	const onCancel = () => {
		toggleCreateOrgModal(false)
		createForm.resetFields()
	}

	const onFinish = (values) => {
		sendCreateOrg(values)
	}

	useEffect(() => {
		if(show){
			createForm.setFieldsValue({partition_key: 'CNAME#', sort_key: 'ORG#'})
		}
	}, [show]);

	return (
		<Modal
			open={!!show}
			onCancel={onCancel}
			footer={null}
			destroyOnClose
			title={'Добавить организацию'}
			width={557}
		>
			<PortalOrgForm
				form={createForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'create'}
			/>
		</Modal>
	);
}

export default CreateOrgModal;
