import {createEffect} from "effector";
import AWS from "aws-sdk";

export const getImageFromS3Fx = createEffect().use(async (payload) => {
	const downloadFile = async () => {
		try {
			const s3 = new AWS.S3({
        accessKeyId: payload.accessKeyId,
        secretAccessKey: payload.secretAccessKey,
				region: 'eu-west-1'
			});

			const params = {
				Bucket: payload.bucket_name,
				Key: payload.object_key,
			}

			const data = await s3.getObject(params).promise()

			const blob = new Blob([data.Body], { type: 'image/jpeg' })
			const url = URL.createObjectURL(blob)
			return url
		} catch (e){
			console.error(e)
			return ''
		}
	}
	return await downloadFile()
})

export const getPlaceIdsFx = createEffect().use(async () => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/CNAME",
			{
				method: "POST",
				body: JSON.stringify({
					action: 'list_place_ids'
				})
			}
		)

		let result = await response.json()
		console.log("getPlaceIdsFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.error(e)
	}
})
