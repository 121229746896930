import React, {useEffect, useState} from 'react';
import style from './PortalUsers.module.css'
import {useUnit} from "effector-react";
import {
	$portalUsersData, archiveUserEv, getPlaceListFx,
	getPortalUsersDataFx, getTenantsListFx,
	setEditUserEv, setViewUserEv, toggleCreatePlaceModalEv,
	toggleCreatePortalUserModalEv, toggleCreateTenantModalEv, sendViewTenantItemEv, toggleCreateOrgModalEv, setEditOrgEv,
} from "../../models/portalUsersModel";
import {Button, Collapse, Popconfirm, Radio, Space, Table, Input} from "antd";
import {useNavigate} from "react-router-dom";
import {$userGroups} from "../../models/userModel";
import {EditOutlined, EyeOutlined, DeleteOutlined, UserAddOutlined, CheckOutlined, PlusOutlined} from "@ant-design/icons";
import CreatePortalUser from "./PortalUsersModals/user/CreatePortalUser";
import EditPortalUser from "./PortalUsersModals/user/EditPortalUser";
import ViewPortalUser from "./PortalUsersModals/user/ViewPortalUser";
import desc from '../../images/desc.svg'
import CreateTenantModal from "./PortalUsersModals/CreateTenantModal";
import PortalPlacesForm from "./PortalUsersModals/place_id/PortalPlacesForm";
import CreatePlaceIdModal from "./PortalUsersModals/place_id/CreatePlaceIdModal";
import EditPlaceIdModal from "./PortalUsersModals/place_id/EditPlaceIdModal";
import ViewPortalPlace from "./PortalUsersModals/place_id/ViewPortalPlace";
import CreateOrgModal from "./PortalUsersModals/organization/CreateOrgModal";
import EditOrgModal from "./PortalUsersModals/organization/EditOrgModal";
import ViewOrgModal from "./PortalUsersModals/organization/ViewOrgModal";

const {Search} = Input

const desc_dict = {
	place_id: 'PLACE ID',
	dashboard_id: 'DASHBOARD ID',
	name: 'ФАМИЛИЯ ИМЯ',
	email: 'EMAIL',
	address: 'АДРЕС',
	active: 'ACTIVE',
	category: 'CATEGORY',
	partition_key: 'PARTITION KEY'
}

function PortalUsers() {
	const portalUsersData = useUnit($portalUsersData)
	const userGroups = useUnit($userGroups);
	const navigate = useNavigate();
	const toggleCreatePortalUserModal = useUnit(toggleCreatePortalUserModalEv)
	const toggleCreatePlaceModal = useUnit(toggleCreatePlaceModalEv)
	const setEditUser = useUnit(setEditUserEv)
	const setViewUser = useUnit(setViewUserEv)
	const archiveUser = useUnit(archiveUserEv)
	const toggleCreateTenantModal = useUnit(toggleCreateTenantModalEv)
	const toggleCreateOrgModal = useUnit(toggleCreateOrgModalEv)
	const setEditOrg = useUnit(setEditOrgEv)

	// const columns = [
	// 	{
	// 		title: "city" ,
	// 		key: "city",
	// 		dataIndex: "city"
	// 	},
	// 	{
	// 		title: "category" ,
	// 		key: "category",
	// 		dataIndex: "category"
	// 	},
	// 	{
	// 		title: "sort_key" ,
	// 		key: "sort_key",
	// 		dataIndex: "sort_key"
	// 	},
	// 	{
	// 		title: "place_address" ,
	// 		key: "place_address",
	// 		dataIndex: "place_address"
	// 	},
	// 	{
	// 		title: "partition_key" ,
	// 		key: "partition_key",
	// 		dataIndex: "partition_key"
	// 	},
	// 	{
	// 		title: "place_id" ,
	// 		key: "place_id",
	// 		dataIndex: "place_id"
	// 	},
	// 	{
	// 		title: "telegram_token" ,
	// 		key: "telegram_token",
	// 		dataIndex: "telegram_token"
	// 	},
	// 	{
	// 		title: "admin_place" ,
	// 		key: "admin_place",
	// 		dataIndex: "admin_place"
	// 	},
	// 	{
	// 		title: "telegram_chat" ,
	// 		key: "telegram_chat",
	// 		dataIndex: "telegram_chat"
	// 	},
	// 	{
	// 		title: "service" ,
	// 		key: "service",
	// 		dataIndex: "service"
	// 	},
	// 	{
	// 		title: "work_zones" ,
	// 		key: "work_zones",
	// 		dataIndex: "work_zones"
	// 	},
	// 	{
	// 		title: "work_hours",
	// 		key: "work_hours",
	// 		dataIndex: "work_hours"
	// 	}
	// ]
	const columns = [
		{
			title: 'actions',
			key: 'actions',
			render: (record) => {
				return <Space>
					<Button icon={<EyeOutlined />} onClick={() => {
						setViewUser(record)
					}}/>
					<Button icon={<EditOutlined />} onClick={() => {
						setEditUser(record)
					}}/>
					{
						record.active === 'true' ?
							<Popconfirm title={'Вы уверены?'} onConfirm={() => archiveUser({
								uid: record.sort_key.replace('UID#', ''),
								partition_key: record.partition_key,
								active: 'false'
							})}>
								<Button icon={<DeleteOutlined />}/>
							</Popconfirm>
							:
							<Button icon={<CheckOutlined />} onClick={() => {
								archiveUser({
									uid: record.sort_key.replace('UID#', ''),
									partition_key: record.partition_key,
									active: 'true'
								})
							}}/>
					}
				</Space>
			}
		},
		{
			title: 'name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'category',
			dataIndex: 'category',
			key: 'category',
		},
		{
			title: 'sort_key',
			dataIndex: 'sort_key',
			key: 'sort_key',
		},
		{
			title: 'active',
			dataIndex: 'active',
			key: 'active',
		},
		{
			title: 'partition_key',
			dataIndex: 'partition_key',
			key: 'partition_key',
		},
	]

	useEffect(() => {
		if(Array.isArray(userGroups) && !userGroups.includes('portal_admin')){
			navigate('/dashboard')
		}
	}, [userGroups, navigate]);

	useEffect(() => {
		if(portalUsersData.length === 0){
			getPortalUsersDataFx()
			getTenantsListFx()
			getPlaceListFx()
		}
	}, []);

	return (
		<div style={{textAlign: 'left', padding: 20}}>
			<CreatePortalUser />
			<EditPortalUser />
			<ViewPortalUser />
			<CreateTenantModal />

			<CreatePlaceIdModal />
			<EditPlaceIdModal />
			<ViewPortalPlace />

			<CreateOrgModal />
			<EditOrgModal />
			<ViewOrgModal />

			<div className={style.portal_users_header}>
				<div className={style.portal_users_title}>Список пользователей</div>
				<div className={style.portal_users_header_right}>
					<Search
						style={{width: 264}}
						placeholder="Поиск"
					/>
					<Button icon={<PlusOutlined />} type={"primary"} onClick={toggleCreateTenantModal}>
						Добавить tenant
					</Button>
				</div>
			</div>
			{/*<Table dataSource={portalUsersData} columns={columns}/>*/}
			<div className={style.branches_wrapper}>
				{
					portalUsersData.map((item, index) => <Branch
						key={`branch-${index}`}

						onCreateUser={toggleCreatePortalUserModal}
						onCreatePlace={toggleCreatePlaceModal}

						onEditUser={setEditUser}
						onEditPlace={''}

						onCreateOrg={toggleCreateOrgModal}
						onEditOrg={setEditOrg}
						{...item}
					/>)
				}
			</div>
		</div>
	);
}

function Branch(props){
	const [tab, setTab] = useState(2)
	const {name, partition_key, users, onCreateUser, onEditUser, onCreatePlace, onEditPlace, onCreateOrg, onEditOrg} = props

	let content
	switch (tab){
		case 0:
		case 1:
		case 2: {
			content =
				<>
					<div className={style.branch_users_wrapper}>
						{
							users.map((item, index) => <BranchItem key={`branch_user-${index}`} user={item} num={index + 1}
																										 onEdit={onEditUser}/>)
						}
					</div>
					<Button icon={<UserAddOutlined/>} type={'primary'} className={style.branch_add_user} onClick={() => {
						onCreateUser(partition_key)
					}}>Добавить
						пользователя</Button>
				</>
			break
		}
		case 3: {
			content = <>
				<Button icon={<PlusOutlined />} type={"primary"} className={style.branch_add_item} onClick={() => onCreatePlace(partition_key)}>Добавить place_id</Button>
			</>
			break
		}
		case 4: {
			content = <>
				<Button icon={<PlusOutlined />} type={"primary"} className={style.branch_add_item} onClick={() => onCreateOrg(partition_key)}>Добавить организацию</Button>
			</>
			break
		}
	}

	return (
		<div className={style.branch_wrapper}>
			<div className={style.branch_top}>
				<div className={style.branch_title}>{name || 'No name'}</div>
				<div className={style.branch_top_right}>
					{/*FIXME Tags*/}
					{/*<div className={[style.tag, style.green_tag].join(' ')}>*/}
					{/*	Marketing*/}
					{/*</div>*/}
					{/*<div className={[style.tag, style.blue_tag].join(' ')}>*/}
					{/*	HR*/}
					{/*</div>*/}
					<Button icon={<EditOutlined/>} className={style.branch_edit_button}>
						Редактировать
					</Button>
				</div>
			</div>
			<Radio.Group style={{marginTop: 10}} value={tab} onChange={(e) => setTab(e.target.value)}>
				<Radio.Button value={0}>Инфа</Radio.Button>
				<Radio.Button value={1}>Контакты</Radio.Button>
				<Radio.Button value={2}>Пользователи</Radio.Button>
				<Radio.Button value={3}>Place ids</Radio.Button>
				<Radio.Button value={4}>Организации</Radio.Button>
			</Radio.Group>
			{content}
		</div>
	)
}

function BranchItem(props){
	// const user={
	// 	place_id: 'amaa25d7c9',
	// 	dashboard_id: '478ed133-4942-4841-8e41-ac3402ef4494',
	// 	full_name: 'Александр Кочетков',
	// 	email: 'a.kochetkov@a-i.kz',
	// 	address: 'ул. Спартака, 26',
	// }
	const {user, num, onEdit} = props

	return <Collapse
		size="small"
		expandIconPosition={"end"}
		items={[
			{
				label: <label className={style.branch_user}>Пользователь №{num}</label>,
				key: '1',
				children: <div >
					<div className={style.branch_desc_wrapper}>
						{
							Object.keys(user).map((key, index) => {
								return <div key={`${key}-${index}`} >
									<div className={style.branch_user_title}>
										{key === 'place_id' || key === 'dashboard_id' ? <img src={desc}/> : ''}
										{desc_dict[key]}:
									</div>
									<div className={style.branch_user_value}>
										{user[key]}
									</div>
								</div>
							})
						}
					</div>
					<div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 8}}>
						<Button
							icon={<EditOutlined/>}
							className={style.branch_edit_button}
							onClick={() => onEdit(user)}
						>
							Редактировать
						</Button>
					</div>
				</div>
			}
		]}
	/>
}

export default PortalUsers;
