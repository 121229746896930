import style from "./Duplicates.module.css";
import { useEffect, useState } from "react";
import { useUnit } from "effector-react";
import {$placeIdOptions, $userGroups} from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Collapse,
  DatePicker,
  Input,
  Pagination,
  Popconfirm,
  Spin,
  Tooltip,
} from "antd";
import {
  $duplicatesDate,
  $duplicatesPlace,
  $duplicatesStore,
  $lastEvaluatedkey,
  changeDuplicateDateEv,
  changeDuplicatesPlaceEv,
  getDuplicatesFx,
  getMoreDuplicatesFx,
  loadMoreEv,
  mergeDuplicatesFx,
} from "../../models/duplicatesModel";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import ArrowUpOutlined from "@ant-design/icons/ArrowUpOutlined";
import ArrowDownOutlined from "@ant-design/icons/ArrowDownOutlined";
import RedoOutlined from "@ant-design/icons/RedoOutlined";

function CollapseItem(props) {
  const {
    matchCount,
    match,
    item,
    handleCopy,
    handleCopyUserId,
    mergeDest,
    mergeItem,
    setMergeDest,
    setMergeItem,
    matchName,
  } = props;

  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [splitedPhotos, setSplitedPhotos] = useState(
    item.concatenatedFiles[match].slice(0, 5),
  );

  console.log("splitedPhotos", splitedPhotos);

  const handleAddToMerge = () => {
    setMergeItem([...mergeItem, matchName]);
  };

  const handleDeleteFromMerge = () => {
    setMergeItem(mergeItem.filter((item) => item !== matchName));
  };

  useEffect(() => {
    setSplitedPhotos(
      item.concatenatedFiles[match].slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize,
      ),
    );
  }, [pageSize, currentPage]);

  return (
    <div style={{ position: "relative" }}>
      <div className={style.collapse_controls}>
        <div className={style.merge_buttons_wrapper}>
          {!mergeItem.includes(matchName) ? (
            <Tooltip
              title={"Мигрировать этого пользователя в другого"}
              placement={"top"}
            >
              <Button
                onClick={handleAddToMerge}
                className={style.collapse_merge_buttons}
                icon={<ArrowUpOutlined />}
                disabled={mergeDest === matchName}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={"Удалить этого пользователя из миграции"}
              placement={"top"}
            >
              <Button
                onClick={handleDeleteFromMerge}
                className={style.collapse_merge_buttons}
                icon={<RedoOutlined />}
              />
            </Tooltip>
          )}
          {mergeDest !== matchName ? (
            <Tooltip
              title={"Мигрировать в этого пользователя"}
              placement={"top"}
            >
              <Button
                onClick={() => setMergeDest(matchName)}
                className={style.collapse_merge_buttons}
                icon={<ArrowDownOutlined />}
                disabled={mergeItem.includes(matchName)}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={"Переназначить пользователя для миграции"}
              placement={"top"}
            >
              <Button
                onClick={() => setMergeDest("")}
                className={style.collapse_merge_buttons}
                icon={<RedoOutlined />}
              />
            </Tooltip>
          )}
        </div>
        <Pagination
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={
            Array.isArray(item.concatenatedFiles[match]) &&
            item.concatenatedFiles[match].length
              ? item.concatenatedFiles[match].length
              : 0
          }
          size={"small"}
          showTotal={(total) => `Total ${total} photos`}
          pageSize={pageSize}
        />
        <Input
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          className={style.collapse_size_input}
          size={"small"}
        />
      </div>
      <Collapse
        key={`duplicates-collapse-${item.user_id}-${match}`}
        expandIconPosition={"end"}
        items={[
          {
            key: `duplicates-collapse-${item.user_id}-${match}`,
            label: (
              <div
                className={style.duplicates_card_title}
              >{`${matchCount} confidence ${item[`${matchCount}_confidence`]}%`}</div>
            ),
            children: (
              <div className={style.duplicates_card_content}>
                {Array.isArray(splitedPhotos) &&
                  splitedPhotos.map((photo, index) => {
                    return (
                      <div key={`duplicates-image-${match}-photo-${index}`}>
                        <div style={{ marginBottom: "10px" }}>
                          File name:
                          <button
                            onClick={() => handleCopy(photo.key.split("/")[1])}
                            style={{
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                          >
                            Click to copy
                          </button>
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                          User ID:
                          <button
                            onClick={() =>
                              handleCopyUserId(photo.key.split("/")[2])
                            }
                            style={{
                              marginLeft: "15px",
                              cursor: "pointer",
                            }}
                          >
                            Click to copy user ID
                          </button>
                        </div>
                        <ImageFromS3
                          // bucket_name={item.s3BucketName}
                          // object_key={photo}
                          presigned_url={photo["presigned-url"]}
                        />
                      </div>
                    );
                  })}
              </div>
            ),
            style: {
              marginBottom: 24,
            },
          },
        ]}
      />
    </div>
  );
}

function DuplicatesCard(props) {
  const { item, handleCopy, handleCopyUserId } = props;

  const mergeDuplicates = useUnit(mergeDuplicatesFx);

  //FIXME Merge destination
  const [mergeDest, setMergeDest] = useState("");
  //FIXME Items to merge in merge destination
  const [mergeItem, setMergeItem] = useState([]);
  const onConfirmMerge = () => {
    mergeDuplicates({
      collection_id: item.collection_id,
      file_name: item.user_id,
      user_id_primary: mergeDest,
      user_id_duplicates: mergeItem,
    });
  };

  return (
    <Card style={{ marginBottom: "20px" }}>
      <div className={style.duplicates_title_wrapper}>
        <div className={style.duplicates_card_title}>{item.user_id}</div>
        <button
          onClick={() => handleCopy(item.user_id)}
          style={{
            marginLeft: "15px",
            cursor: "pointer",
          }}
        >
          Click to copy
        </button>
        {/*<button*/}
        {/*  onClick={() => handleCopyUserId(item.user_id.split('/')[1])}*/}
        {/*  style={{*/}
        {/*    marginLeft: '15px',*/}
        {/*    cursor: 'pointer'*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Click to copy user ID*/}
        {/*</button>*/}
      </div>
      <div className={style.duplicates_card_wrapper}>
        <ImageFromS3
          // bucket_name={item.s3BucketName}
          // object_key={item.pathForMainPhoto}
          bucket_name={"prod-environment-foqus"}
          object_key={`data-stream/${item.place_id}/${item.date_gsi.split("-").reverse().join("-")}/face-scan/${item.user_id}`}
          presigned_url={item.presignedUrlForMainPhoto}
        />
        <div className={style.duplicates_card_description}>
          <div>Date: {item.date_gsi}</div>
          <div>Place id: {item.place_id}</div>
          <div>Status: {item.status}</div>
          <div>Collection id: {item.collection_id}</div>
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={onConfirmMerge}
            okText={"Yes"}
            cancelText={"No"}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
          >
            <Button
              className={style.merge_button}
              disabled={mergeDest === "" || mergeItem.length === 0}
            >
              Merge
            </Button>
          </Popconfirm>
        </div>
      </div>
      {Object.keys(item.concatenatedFiles).map((match) => {
        if (item.concatenatedFiles[match].length > 0) {
          const matchCount = match.split("_")[0];
          return (
            <CollapseItem
              key={`duplicates-match-${item.user_id}-${item[match]}`}
              item={item}
              match={match}
              matchCount={matchCount}
              handleCopy={handleCopy}
              handleCopyUserId={handleCopyUserId}
              mergeDest={mergeDest}
              mergeItem={mergeItem}
              setMergeDest={setMergeDest}
              setMergeItem={setMergeItem}
              matchName={item[match]}
            />
          );
        }
        return null;
      })}
    </Card>
  );
}


export default function Duplicates() {
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const duplicatesDate = useUnit($duplicatesDate);
  const changeDuplicateDate = useUnit(changeDuplicateDateEv);
  const duplicatesStore = useUnit($duplicatesStore);
  const changeDuplicatesPlace = useUnit(changeDuplicatesPlaceEv);
  const duplicatesPlace = useUnit($duplicatesPlace);
  const lastEvaluatedKey = useUnit($lastEvaluatedkey);
  const loadMore = useUnit(loadMoreEv);
  const moreLoading = useUnit(getMoreDuplicatesFx.pending);
  const placeIdOptions = useUnit($placeIdOptions)

  const handleCopy = (hiddenText) => {
    navigator.clipboard
      .writeText(hiddenText)
      .then(() => {
        console.log("Text copied to clipboard:", hiddenText);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCopyUserId = (hiddenText) => {
    navigator.clipboard
      .writeText(hiddenText)
      .then(() => {
        console.log("Text copied to clipboard:", hiddenText);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (duplicatesStore.length === 0) {
      getDuplicatesFx();
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  return (
    <div className={style.duplicates_wrapper}>
      <div className={style.duplicates_title}>Duplicates</div>
      <DatePicker
        className={style.duplicates_datepicker}
        value={duplicatesDate}
        onChange={changeDuplicateDate}
      />
      <CustomSelect
        options={placeIdOptions}
        onSelect={changeDuplicatesPlace}
        field={"client_id"}
        placeholder="Place"
        value={duplicatesPlace}
      />
      {duplicatesStore.map((item, index) => {
        return (
          <DuplicatesCard
            key={`duplicates-card-${index}`}
            item={item}
            handleCopy={handleCopy}
            handleCopyUserId={handleCopyUserId}
          />
        );
      })}

      {lastEvaluatedKey ? (
        <Button
          type={"primary"}
          className={style.load_more_button}
          onClick={loadMore}
          loading={moreLoading}
        >
          Загрузить ещё
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}
