import React, { useEffect, useMemo, useState } from "react";
import { Button, Segmented, Table, Modal, Form, Input, message } from "antd";
import {
  EditOutlined,
  UploadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import style from "./SchoolUserList.module.css";
import PotentialEmp from "./PotentialEmp";
import UserListModal from "./UserListModal";
import CreateUserModal from "./CreateUserModal";
import MakeEmpModal from "./MakeEmpModal";
import PotentialImagesModal from "./PotentialEmpImagesModal";
import {
  $userEmpCategory,
  $userList,
  $userListEdu,
  changeUserEmpCategoryEv,
  changeImportFileModalEv,
  changeShowCreateModalEv,
  getUserListEduFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { $userGroups } from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import { edu_field_names } from "../../dictionaries/edu-dictionary";
import ImportFilesModal from "./ImportFilesModal";

function SchoolUserList() {
  const userEmpCategory = useUnit($userEmpCategory);
  const changeUserEmpCategory = useUnit(changeUserEmpCategoryEv);
  const [tab, setTab] = useState(0);
  const userList = useUnit($userListEdu);
  const loading = useUnit(getUserListEduFx.pending);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const changeImportFileModal = useUnit(changeImportFileModalEv);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);

  const updateStudentInfo = async (values) => {
    const payload = {
      type: "student",
      partition_key: values.partition_key,
      sort_key: values.sort_key,
      data: {
        user_name: values.user_name,
        user_surname: values.user_surname,
        grade: values.grade,
      },
    };

    console.log("Payload being sent to API:", payload);

    try {
      const response = await fetch(
        "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/ChangeEDUInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! Status: ${response.status}, Message: ${errorText}`
        );
      }

      const responseText = await response.text();
      const result = responseText ? JSON.parse(responseText) : null;

      console.log("API Response:", result);

      message.success("Student information updated successfully!");
      return result;
    } catch (error) {
      console.error("Failed to update student info:", error);
      message.error(
        "Failed to update student information. Check the console for details."
      );
      throw error;
    }
  };

  const showEditModal = (record) => {
    setEditingRecord(record);
    setIsEditModalVisible(true);
  };

  const handleEditModalOk = async (values) => {
    try {
      const recordWithKeys = {
        ...values,
        partition_key: editingRecord.partition_key,
        sort_key: editingRecord.sort_key,
      };

      if (userEmpCategory === "Ученик") {
        await updateStudentInfo(recordWithKeys);
      }

      setIsEditModalVisible(false);

      // Refresh the user list to reflect changes
      await getUserListEduFx();
    } catch (error) {
      console.error("Error updating record:", error);
    }
  };

  const handleEditModalCancel = () => {
    setIsEditModalVisible(false);
  };

  useEffect(() => {
    if (
      (Array.isArray(userGroups) &&
        userGroups.includes("Customer") &&
        userGroups.includes("Aubakirova")) ||
      (Array.isArray(userGroups) && userGroups.includes("portal_admin"))
    ) {
      console.log("got perms for User List");
    } else {
      console.log("redirected from User List");
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  useEffect(() => {
    getUserListEduFx();
  }, []);

  useEffect(() => {
    console.log("User List Updated:", userList);
  }, [userList]);

  const columns = useMemo(() => {
    return [
      {
        title: "Действия",
        key: "actions",
        render: (text, record) => (
          <Button
            icon={<EditOutlined />}
            onClick={() => showEditModal(record)}
            style={{ width: "24px", height: "24px" }}
          />
        ),
      },
      {
        title: edu_field_names.user_name,
        dataIndex: "user_name",
        key: "user_name",
      },
      {
        title: edu_field_names.user_surname,
        dataIndex: "user_surname",
        key: "user_surname",
      },
      {
        title: edu_field_names.grade,
        dataIndex: "grade",
        key: "grade",
      },
      {
        title: edu_field_names.user_gender,
        dataIndex: "user_gender",
        key: "user_gender",
      },
      {
        title: edu_field_names.user_birth_day,
        dataIndex: "user_birth_day",
        key: "user_birth_day",
      },
      {
        title: edu_field_names.user_birth_month,
        dataIndex: "user_birth_month",
        key: "user_birth_month",
      },
      {
        title: edu_field_names.user_birth_year,
        dataIndex: "user_birth_year",
        key: "user_birth_year",
      },
      {
        title: edu_field_names.user_email,
        dataIndex: "user_email",
        key: "user_email",
      },
      {
        title: edu_field_names.contacts,
        dataIndex: "contacts",
        key: "contacts",
        render: (contacts) =>
          contacts?.map((contact, index) => (
            <div key={`contact-${index}`}>
              <div>{`Mobile: ${contact.contact_mobile}`}</div>
              <div>{`Name: ${contact.contact_name}`}</div>
              <div>{`Surname: ${contact.contact_surname}`}</div>
              <div>{`Type: ${contact.contact_type}`}</div>
              <div>{`Email: ${contact.contact_email}`}</div>
            </div>
          )),
      },
      {
        title: edu_field_names.user_address,
        dataIndex: "user_address",
        key: "user_address",
      },
      {
        title: edu_field_names.user_mobile,
        dataIndex: "user_mobile",
        key: "user_mobile",
      },
    ];
  }, []);

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>Список сотрудников</div>
      <Segmented
        options={[
          { label: "Все", value: 0 },
          { label: "Потенциальные сотрудники", value: 1 },
        ]}
        value={tab}
        onChange={setTab}
      />
      {tab === 0 ? (
        <>
          <div className={style.user_list_top_wrapper}>
            <Segmented
              options={[
                { label: "Сотрудник", value: "Сотрудник" },
                { label: "Ученик", value: "Ученик" },
              ]}
              value={userEmpCategory}
              onChange={changeUserEmpCategory}
            />
            <div className={style.top_buttons_wrapper}>
              <Button
                className={style.add_user_button}
                icon={<UploadOutlined />}
                onClick={changeImportFileModal}
                type={"primary"}
              >
                Импорт данных
              </Button>
              <Button
                className={style.add_user_button}
                icon={<UserAddOutlined />}
                onClick={changeShowCreateModal}
                type={"primary"}
              >
                Добавить сотрудника
              </Button>
            </div>
          </div>
          <Table
            loading={loading}
            className={style.userList_table}
            dataSource={userList}
            columns={columns}
            scroll={{ x: "max-content" }}
            bordered
            pagination={{
              locale: { items_per_page: "стр." },
            }}
          />
        </>
      ) : (
        <PotentialEmp />
      )}
      <UserListModal />
      <CreateUserModal />
      <MakeEmpModal />
      <PotentialImagesModal />
      <ImportFilesModal />
      {isEditModalVisible && (
        <Modal
          title="Редактировать данные ученика"
          visible={isEditModalVisible}
          onCancel={handleEditModalCancel}
          footer={null}
        >
          <Form
            layout="vertical"
            initialValues={editingRecord}
            onFinish={handleEditModalOk}
          >
            <Form.Item
              name="user_name"
              label="Имя ученика"
              rules={[{ required: true, message: "Введите имя ученика" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="user_surname"
              label="Фамилия ученика"
              rules={[{ required: true, message: "Введите фамилию ученика" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="grade"
              label="Класс"
              rules={[{ required: true, message: "Введите класс" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default SchoolUserList;
