import React from 'react';
import {Form, Modal} from "antd";
import {useUnit} from "effector-react";
import {
	sendCreatePortalUserEv,
	$showCreatePortalUserModal,
	toggleCreatePortalUserModalEv, sendCreatePortalUserFx
} from "../../../../models/portalUsersModel";
import PortalUserForm from "./PortalUserForm";

function CreatePortalUser() {
	const show = useUnit($showCreatePortalUserModal)
	const toggleCreatePortalUserModal = useUnit(toggleCreatePortalUserModalEv)
	const [createForm] = Form.useForm()
	const sendCreatePortalUser = useUnit(sendCreatePortalUserEv)
	const loading = useUnit(sendCreatePortalUserFx.pending)

	const onCancel = () => {
		toggleCreatePortalUserModal(false)
		createForm.resetFields()
	}

	const onFinish = (values) => {
		const {uid, ...rest} = values
		sendCreatePortalUser({
			uid,
			values: {
				...rest,
				partition_key: show,
			}
		})
	}

	return (
		<Modal
			open={!!show}
			onCancel={onCancel}
			footer={null}
			destroyOnClose
			title={'Добавить пользователя'}
			width={557}
		>
			<PortalUserForm
				form={createForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'create'}
			/>
		</Modal>
	);
}

export default CreatePortalUser;
