import React from 'react';
import {$chosenViewEdu, resetViewEduEv, setEditUserEduEv} from "../../models/userListModel";
import {useUnit} from "effector-react";
import {Button, Image, Modal} from "antd";
import style from './SchoolUserList.module.css'
import {EditOutlined} from "@ant-design/icons";

function ViewUserModal({type}) {
	const chosenViewEdu = useUnit($chosenViewEdu)
	const resetViewEdu = useUnit(resetViewEduEv)
	const setEditUserEdu = useUnit(setEditUserEduEv)

	return (
		<Modal
			open={!!chosenViewEdu}
			onCancel={resetViewEdu}
			footer={null}
			destroyOnClose
			width={800}
		>
			<div className={style.user_modal_title}>Персональные данные</div>
			<div className={style.user_modal_top}>
				<Image src={chosenViewEdu?.image}/>
				<div style={{width: 'calc(100% - 220px)'}}>
					{
						type === 'employee' ?
							<>
								<div className={style.card_info_top}>
									<div className={style.card_info_name}>{`${chosenViewEdu?.name} ${chosenViewEdu?.surname}`}</div>
									<div className={style.card_info_type}>{type === 'employee' ? 'Сотрудник' : 'Школьник'}</div>
								</div>
								<div className={style.divider}/>
								<div className={style.card_info_wrapper}>
									<div>
										<div>
											<div className={style.card_info_label}>
												Департамент:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.department}
											</div>
										</div>
										<div style={{marginTop: 6}}>
											<div className={style.card_info_label}>
												Должность:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.position}
											</div>
										</div>
									</div>
									<div>
										<div>
											<div className={style.card_info_label}>
												Управление:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.management}
											</div>
										</div>
										<div style={{marginTop: 6}}>
											<div className={style.card_info_label}>
												Предметы:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.subjects.join(', ')}
											</div>
										</div>
									</div>
								</div>
							</>
							:
							<>
								<div className={style.card_info_top}>
									<div className={style.card_info_name}>{`${chosenViewEdu?.name} ${chosenViewEdu?.surname}`}</div>
									<div className={style.card_info_type}>{type === 'employee' ? 'Сотрудник' : 'Школьник'}</div>
								</div>
								<div className={style.divider}/>
								<div className={style.card_info_wrapper}>
									<div>
										<div>
											<div className={style.card_info_label}>
												Класс:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.class}
											</div>
										</div>
										<div style={{marginTop: 6}}>
											<div className={style.card_info_label}>
												Дата рождения:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.birthday}
											</div>
										</div>
									</div>
									<div>
										<div>
											<div className={style.card_info_label}>
												Смена:
											</div>
											<div className={style.card_info_value}>
												{`${chosenViewEdu?.shift}-я смена`}
											</div>
										</div>
										<div style={{marginTop: 6}}>
											<div className={style.card_info_label}>
												Предметы:
											</div>
											<div className={style.card_info_value}>
												{chosenViewEdu?.subjects.join(', ')}
											</div>
										</div>
									</div>
								</div>
							</>
					}
				</div>
			</div>
			<div className={style.user_modal_info_block} style={{marginTop: 20}}>
				{
					type === 'employee' ?
						<>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Дата рождения:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.birthday}
								</div>
							</div>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Пол:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.gender}
								</div>
							</div>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Дата приема на работу:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.emp_date}
								</div>
							</div>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Дата увольнения:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.fire_date || '-'}
								</div>
							</div>
						</>
						:
						<>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Пол:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.gender}
								</div>
							</div>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Время начала раб. дня:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.start_work_time}
								</div>
							</div>
							<div className={style.card_info_block}>
								<div className={style.card_info_label}>
									Время окончания раб. дня:
								</div>
								<div className={style.card_info_value}>
									{chosenViewEdu?.end_work_time}
								</div>
							</div>
						</>
				}
			</div>
			{
				type === 'employee' ?
					<div className={style.user_modal_info_block} style={{marginTop: 10}}>
						<div className={style.card_info_block}>
							<div className={style.card_info_label}>
								График работы:
							</div>
							<div className={style.card_info_value}>
								{chosenViewEdu?.work_type}
							</div>
						</div>
						<div className={style.card_info_block}>
							<div className={style.card_info_label}>
								Время начала раб. дня:
							</div>
							<div className={style.card_info_value}>
								{chosenViewEdu?.start_work_time}
							</div>
						</div>
						<div className={style.card_info_block}>
							<div className={style.card_info_label}>
								Время окончания раб. дня:
							</div>
							<div className={style.card_info_value}>
								{chosenViewEdu?.end_work_time}
							</div>
						</div>
						<div className={style.user_modal_divider}/>
						<div className={style.card_info_block}>
							<div className={style.card_info_label}>
								Почта:
							</div>
							<div className={style.card_info_value}>
								{chosenViewEdu?.email}
							</div>
						</div>
						<div className={style.card_info_block}>
							<div className={style.card_info_label}>
								Телефон:
							</div>
							<div className={style.card_info_value}>
								{chosenViewEdu?.phone}
							</div>
						</div>
						<div className={style.card_info_block}>
							<div className={style.card_info_label}>
								Адрес:
							</div>
							<div className={style.card_info_value}>
								{chosenViewEdu?.address}
							</div>
						</div>
					</div>
					:
					<>
						{
							chosenViewEdu?.contacts.map((item, index) => {
								return <div key={`contact-${index}`} className={style.user_modal_info_block} style={{marginTop: 10}}>
									<div className={style.card_info_block}>
										<div className={style.card_info_label}>
											Тип контакта:
										</div>
										<div className={style.card_info_value}>
											{item.type}
										</div>
									</div>
									<div className={style.card_info_block}>
										<div className={style.card_info_label}>
											Имя:
										</div>
										<div className={style.card_info_value}>
											{item.name}
										</div>
									</div>
									<div className={style.card_info_block}>
										<div className={style.card_info_label}>
											Фамилия:
										</div>
										<div className={style.card_info_value}>
											{item.surname}
										</div>
									</div>
									<div className={style.user_modal_divider}/>
									<div className={style.card_info_block}>
										<div className={style.card_info_label}>
											Телефон:
										</div>
										<div className={style.card_info_value}>
											{item.phone}
										</div>
									</div>
									<div className={style.card_info_block}>
										<div className={style.card_info_label}>
											Почта:
										</div>
										<div className={style.card_info_value}>
											{item.email}
										</div>
									</div>
									<div className={style.card_info_block}>
										<div className={style.card_info_label}>
											Адрес:
										</div>
										<div className={style.card_info_value}>
											{item.address}
										</div>
									</div>
								</div>
							})
						}
					</>
			}
			<div className={style.user_modal_buttons_wrapper}>
				<Button icon={<EditOutlined/>} className={style.user_modal_button} onClick={() => setEditUserEdu(chosenViewEdu)}>Редактировать</Button>
				<Button className={style.user_modal_button} style={{border: "unset"}}>Удалить</Button>
			</div>
		</Modal>
	);
}

export default ViewUserModal;
