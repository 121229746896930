import {
	$editOrg,
	$editPortalPlace,
	$editPortalUser,
	$placesList,
	$portalUsersData, $showCreateOrgModal, $showCreatePlaceModal,
	$showCreatePortalUserModal, $showCreateTenantModal,
	$tenantsList, $viewOrg, $viewPlaceData,
	$viewPortalUser
} from "./stores";
import {
	archiveUserFx, getPlaceListFx,
	getPortalUsersDataFx, getTenantsListFx, sendCreateTenantItemFx,
	sendCreatePortalUserFx, sendEditTenantItemFx,
	sendEditPortalUserFx, sendViewTenantItemFx, viewPlaceFx,
	viewPortalUserFx, sendDisableTenantItemFx, sendActivateTenantItemFx
} from "./effects";
import {
	archiveUserEv,
	resetEditPlaceEv,
	resetEditUserEv,
	resetViewPortalPlaceEv,
	resetViewUserEv,
	sendCreateTenantItemEv,
	sendCreatePortalUserEv,
	sendEditTenantItemEv,
	sendEditPortalUserEv,
	sendViewPortalUserEv,
	setEditPlaceEv,
	setEditUserEv,
	setViewUserEv,
	toggleCreatePlaceModalEv,
	toggleCreatePortalUserModalEv,
	toggleCreateTenantModalEv,
	sendViewTenantItemEv,
	toggleCreateOrgModalEv,
	setEditOrgEv,
	resetEditOrgEv,
	resetViewOrgEv,
	sendDisableTenantItemEv,
	sendActivateTenantItemEv,
	resetCreatePlaceModalEv,
	resetCreateOrgModalEv
} from "./events";
import {sample} from "effector";
import {$userAttributes, setUserUIDDataEv} from "../userModel";

$portalUsersData.on(getPortalUsersDataFx.doneData, (_, payload) => payload)

$showCreatePortalUserModal.on(toggleCreatePortalUserModalEv, (state, payload) => payload)

$editPortalUser.on(setEditUserEv, (_, payload) => payload)
	.reset(resetEditUserEv)

$viewPortalUser.on(setViewUserEv, (_, payload) => payload)
	.reset(resetViewUserEv)

$tenantsList.on(getTenantsListFx.doneData, (_, payload) => {
	return payload.map(item => {
		return {
			label: item.name,
			value: item.partition_key
		}
	})
})

$placesList.on(getPlaceListFx.doneData, (_, payload) => {
	return payload.map(item => {
		return {
			label: item.place_address,
			value: item.place_id
		}
	})
})

$showCreateTenantModal.on(toggleCreateTenantModalEv, (state, payload) => {
	if(typeof payload === 'boolean') return payload
	return !state
})

$showCreatePlaceModal.on(toggleCreatePlaceModalEv, (state, payload) => payload)
	.reset(resetCreatePlaceModalEv)
$editPortalPlace.on(setEditPlaceEv, (_, payload) => payload)
	.reset(resetEditPlaceEv)

$viewPlaceData.on(sendViewTenantItemFx.doneData, (_, payload) => payload)
	.reset(resetViewPortalPlaceEv)

$showCreateOrgModal.on(toggleCreateOrgModalEv, (state, payload) => payload)
	.reset(resetCreateOrgModalEv)
$editOrg.on(setEditOrgEv, (_, payload) => payload)
	.reset(resetEditOrgEv)

$viewOrg.on(sendViewTenantItemFx.doneData, (_, payload) => payload)
	.reset(resetViewOrgEv)

sample({
	clock: sendCreatePortalUserEv,
	fn: (clock) => {
		return {
			action: 'create_uid',
			uid: clock.uid,
			properties: {
				...clock.values,
			}
		}
	},
	target: sendCreatePortalUserFx
})

sample({
	source: $userAttributes,
	clock: sendEditPortalUserEv,
	fn: (source, clock) => {
		return {
			action: 'update_uid',
			// uid: clock.sub,
			uid: clock.uid,
			properties: {
				...clock.values,
			}
		}
	},
	target: sendEditPortalUserFx
})

sample({
	clock: archiveUserEv,
	fn: (clock) => {
		return {
			action: 'update_uid',
			uid: clock.uid,
			properties: {
				active: clock.active
			}
		}
	},
	target: archiveUserFx
})

sample({
	source: $portalUsersData,
	clock: sendCreatePortalUserFx.doneData,
	fn: (source, clock) => {
		const updateIndex = source.findIndex(item => item.partition_key === clock.partition_key.S)
		return [
			...source.slice(0, updateIndex),
			{
				...source[updateIndex],
				users: [
					Object.fromEntries(Object.keys(clock[0]).map(item => [item, clock[0][item].S])),
					...source[updateIndex].users
				]
			},
			...source.slice(updateIndex + 1)
		]
	},
	target: [$portalUsersData, toggleCreatePortalUserModalEv]
})

sample({
	source: $portalUsersData,
	clock: [sendEditPortalUserFx.doneData, archiveUserFx.doneData],
	fn: (source, clock) => {
		const {sort_key} = clock
		const updateIndex = source.findIndex(item => item.users.map(elem => elem.sort_key).includes(sort_key))
		return [
			...source.slice(0, updateIndex),
			{
				...source[updateIndex],
				users: [
					clock,
					...source[updateIndex].users.filter(item => item.sort_key !== sort_key),
				]
			},
			...source.slice(updateIndex + 1)
		]
	},
	target: [$portalUsersData, resetEditUserEv]
})

sample({
	clock: $userAttributes.updates,
	filter: (clock) => clock.sub,
	fn: (clock) => {
		return {
			action: 'read_uid',
			uid: clock.sub
		}
	},
	target: viewPortalUserFx
})

sample({
	clock: viewPortalUserFx.doneData,
	fn: (clock) => {
		return Object.fromEntries(Object.keys(clock).map(item => [item, clock[item].S]))
	},
	target: setUserUIDDataEv
})

sample({
	clock: sendCreateTenantItemEv,
	fn: (clock) => {
		return {
			action: 'create',
			body: clock
		}
	},
	target: sendCreateTenantItemFx
})


sample({
	source: $portalUsersData,
	clock: sendCreateTenantItemFx.doneData,
	filter: (_, clock) => clock.sort_key.includes('PLACEID#') || clock.sort_key.includes('ORG#'),
	fn: (source, clock) => {
		const {partition_key} = clock
		const updateIndex = source.findIndex(item => item.partition_key === partition_key)
		const created_type = clock.sort_key.includes('PLACEID#') ? 'place' : 'organizations'
		return [
			...source.slice(0, updateIndex),
			{
				...source[updateIndex],
				[created_type]: [
					clock,
					...source[updateIndex][created_type],
				]
			},
			...source.slice(updateIndex + 1)
		]
	},
	target: [$portalUsersData, resetCreatePlaceModalEv, resetCreateOrgModalEv]
})

sample({
	clock: sendEditTenantItemEv,
	fn: (clock) => {
		return {
			action: 'update',
			body: clock
		}
	},
	target: sendEditTenantItemFx
})

sample({
	source: $portalUsersData,
	clock: [sendEditTenantItemFx.doneData, sendDisableTenantItemFx.doneData, sendActivateTenantItemFx.doneData],
	filter: (_, clock) => clock.sort_key.includes('PLACEID#') || clock.sort_key.includes('ORG#'),
	fn: (source, clock) => {
		const {partition_key} = clock
		const updateIndex = source.findIndex(item => item.partition_key === partition_key)
		const created_type = clock.sort_key.includes('PLACEID#') ? 'place' : 'organizations'
		return [
			...source.slice(0, updateIndex),
			{
				...source[updateIndex],
				[created_type]: [
					clock,
					...source[updateIndex][created_type].filter(item => item.sort_key !== clock.sort_key),
				]
			},
			...source.slice(updateIndex + 1)
		]
	},
	target: [$portalUsersData, resetEditPlaceEv, resetEditOrgEv]
})

sample({
	clock: sendViewTenantItemEv,
	fn: (clock) => {
		return {
			action: 'read',
			body: {
				partition_key: clock.partition_key ||  "test2",
				sort_key: clock.sort_key ||  'PLACEID#test2',
			}
		}
	},
	target: sendViewTenantItemFx
})

sample({
	clock: sendDisableTenantItemEv,
	fn: (clock) => {
		return {
			action: 'disable',
			body: {
				partition_key: clock.partition_key,
				sort_key: clock.sort_key
			}
		}
	},
	target: sendDisableTenantItemFx
})

sample({
	clock: sendActivateTenantItemEv,
	fn: (clock) => {
		return {
			action: 'activate',
			body: {
				partition_key: clock.partition_key,
				sort_key: clock.sort_key
			}
		}
	},
	target: sendActivateTenantItemFx
})
